import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Slider from "react-slick";
import { SectionTitle } from "../styles/styles"

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div style={{ textAlign: 'center' }}>
        <SectionTitle style={{ marginBottom: '16px', display: 'inline-block' }}>Testimonials</SectionTitle>
        <Slider {...settings}>
          <div style={{ padding: '16px', textAlign: 'center !important' }}>
            <p>"I would not change anything …..Best course I have ever done"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"A fun and useful course"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"I learnt a lot more than I thought I would"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"I met my objectives and enjoyed the whole course"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"Very relevant to real events"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"Every activity was different and yet all equally as enjoyable, engaging and informative"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"Much more engaging than any D&amp;I courses I have ever done"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"Highly positive impact &amp; feel. I have a new &amp; refreshed outlook on life"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"Highly positive impact &amp; feel. I have a new &amp; refreshed outlook on life"</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"The sessions helped me figure out where the problem started and how to fix it and use it to fix other problems. I know the feeling I might get and what to do at the time and put in the things to change it."</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"The sessions helped me figure out where the problem started and how to fix it and use it to fix other problems. I know the feeling I might get and what to do at the time and put in the things to change it."</p>
          </div>
          <div style={{ padding: '16px', textAlign: 'center' }}>
            <p>"My session really helped put things in perspective - it helped me pluck up the courage to move on in my career…… I was able to look at the pros and cons with a clear attitude.  I now feel very positive about my future."</p>
          </div>
        </Slider>
      </div>
    );
  }
}